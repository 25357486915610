:root {
  --brand: transparent; /*use*/
  --brand-hover: transparent; /*use*/
  --brand-active: transparent; /*use*/
  --baseBorder: #ccc; /*use*/
  --textGray: #898984; /*use*/
  --secondary: #000000;

  --headerIcon: #000000; /*use*/
  --blueText: #7da4db; /*use*/
  --grayBlue: #6e83a1; /*use*/

  --grayBg: #f1f1f1; /*use*/

  --topMenuText: #10141c; /*use*/

  --cardImg: #f9fafc; /*use*/

  --accordionBg: #f2f4f5; /*use*/

  --green: #00b67a;
  --lightGreen: #f8fff9;
  --red: #b00707;
  --lightRed: #ffeaea;

  --groupedInputBg: #fff;

  --primary-font: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  --secondary-font: -apple-system, BlinkMacSystemFont, Arial, sans-serif;

  --border-radius-banner: 0px;
  --border-radius-brand: 8px;

  --shipping-returns-bg: #fff;
  --top-panel-bg: transparent;
  --accordion-text: transparent;
  --accordion-active: transparent;
  --accordion-expand-icon: transparent;

  /* Product details page configuration */
  --product-slider-image-border-radius: 8px,
  --quantity-selection-button-color: transparent,
  --product-delivery-details-color: transparent,
  --product-details-bullet-points-color: transparent,
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
.appContainer {
  font-family: var(--primary-font);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #000000;
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  @apply font-normal;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.link {
  color: var(--brand);
  @apply font-semibold;
}

.hov:hover {
  color: var(--brand-hover);
}

.panel {
  font-size: 14px;
  margin-bottom: 30px;
}

.panel > p {
  display: none;
}

.accordion-wrapper {
  border-bottom: 1px solid var(--baseBorder);
}

.accordion-wrapper:first-of-type {
  border-top: 1px solid var(--baseBorder);
}

.accordion-title {
  @apply text-accordionText;
  list-style: none;
  font-weight: 600;
  cursor: pointer;
  padding: 15px 0;
  padding-right: 15px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.3s;
  position: relative;
}

.accordion-title:after {
  @apply text-accordionExpandIcon;
  content: '+';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 30px;
  font-weight: normal;
}

.accordion-wrapper[open] .accordion-title {
  @apply text-accordionActive;
}

.accordion-wrapper[open] .accordion-title:after {
  @apply text-accordionActive;
  content: '-';
  transition: 0.3s;
}

.accordion-content {
  padding-left: 3px;
  padding-bottom: 15px;
  font-weight: 300;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.floatLabelWrapper {
  position: relative;
}

.floatLabel {
  z-index: 1;
  font-size: 14px;
  color: var(--textGray);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) scale(1);
  left: 15px;
  font-weight: normal;
  transform-origin: top left;
  transition: all 0.1s ease-in-out;
}

.floatLabelWrapper > input::placeholder,
.floatLabelWrapper > input::placeholder {
  opacity: 0;
}

.floatLabelWrapper.active .floatLabel,
.floatLabelWrapper input:not(:placeholder-shown) ~ label.floatLabel,
.floatLabelWrapper input:-webkit-autofill ~ label.floatLabel {
  transform: translate(0, -80%) scale(0.7);
  color: var(--textGray);
}

.floatLabelWrapper.active {
  background-color: initial;
}

.floatLabel.invalid {
  color: red;
}

.floatLabelWrapper.invalid.active .floatLabel.invalid,
.floatLabelWrapper input:not(:placeholder-shown) ~ label.floatLabel.invalid,
.floatLabelWrapper input:-webkit-autofill ~ label.floatLabel.invalid {
  transform: translate(0, -80%) scale(0.7);
  color: red;
}

.Modal_close__AXBq5{
  margin: 1rem;
}
@media screen and (max-width: 450px) {
  .ProductStickyCart_root__XfXtR .order-3 button{
    padding: 11px 9px;
    font-size: 4vw;
   }
}

.Modal_close__AXBq5{
  margin: 1rem;
}
@media screen and (max-width: 450px) {
  .ProductStickyCart_root__XfXtR .order-3 button{
    padding: 11px 9px;
    font-size: 4vw;
   }
}

.zsiq_floatmain.siq_bR {
  bottom: 100px;
  z-index: 100 !important;
}

.siq_bR .zsiq_flt_rel {
  position: unset !important;
  background-color: var(--message-us-scroll-color) !important;
}

.account-wishlist .swiper-wrapper .swiper-slide {
  margin-right: 30px !important;
}