@import './fonts.css';

@tailwind base;

@tailwind components;
@import './components.css';

@tailwind utilities;

@media screen and (max-width: 718px){
    #g-recaptcha{
        transform: scale(0.8)
    }
}
@import './base.css';

